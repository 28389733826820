import React from 'react';
import { oneOfType, element, arrayOf, func, node } from 'prop-types';
import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
`;

const Container = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};

Container.propTypes = {
  children: oneOfType([
    element,
    node,
    arrayOf(oneOfType([element, node, func]))
  ])
};

Container.defaultProps = {
  children: <></>
};

export default Container;
