import React from 'react';
import styled from 'styled-components';
import { oneOfType, element, node, func, arrayOf } from 'prop-types';

const SectionTitleStyles = styled.h2`
  color: #ffffff;
  border-radius: 13rem;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 4px solid rgb(255, 0, 128);
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  background: linear-gradient(to right, rgb(255, 0, 128), rgb(255, 170, 213));
  font-size: 1.1rem;

  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 1.3rem;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const SectionTitle = ({ children }) => {
  return <SectionTitleStyles>{children}</SectionTitleStyles>;
};

SectionTitle.propTypes = {
  children: oneOfType([
    element,
    node,
    arrayOf(oneOfType([element, node, func]))
  ])
};

SectionTitle.defaultProps = {
  children: <></>
};

export default SectionTitle;
