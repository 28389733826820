import React from 'react';
import styled from 'styled-components';
import { oneOfType, element, node, func, arrayOf } from 'prop-types';

const StyledLabel = styled.label`
  font-weight: bold;
  text-align: left;
  width: 100%;
  display: block;
`;

const Label = ({ children }) => {
  return <StyledLabel>{children}</StyledLabel>;
};

Label.propTypes = {
  children: oneOfType([
    element,
    node,
    arrayOf(oneOfType([element, node, func]))
  ])
};

Label.defaultProps = {
  children: <></>
};

export default Label;
