import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  background-color: white;
  color: rgb(23, 32, 42);
  width: 100%;
  display: block;
  font-size: 1.125rem;
  border-style: none none solid;
  border-bottom: 2px solid rgb(255, 170, 213);
  padding: 1.125rem 0px;
  border-radius: 0px;
  width: 100%;

  &:first-of-type {
    margin-right: 1rem;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  border-bottom: 2px solid rgb(255, 170, 213);

  &:active,
  &:hover {
    border-bottom: 2px solid #ff0080;
  }

  .hasValue {
    border-bottom: 2px solid #2cb33d;

    &:active,
    &:hover {
      border-bottom: 2px solid #2cb33d;
    }
  }
`;

const Input = (props) => {
  return <StyledInput {...props} />;
};

export default Input;
