import React from 'react';
import styled from 'styled-components';
import { oneOfType, element, node, func, arrayOf } from 'prop-types';

const ButtonStyles = styled.button`
  color: #ffffff;
  border-radius: 13rem;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #2cb33d;
  font-size: 1.3rem;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  width: 300px;
  font-weight: bold;
  cursor: pointer;
  position: relative;

  svg {
    position: absolute;
    right: 15px;
    top: 12px;
  }
`;

const Button = (props) => {
  return <ButtonStyles {...props}>{props.children}</ButtonStyles>;
};

Button.propTypes = {
  children: oneOfType([
    element,
    node,
    arrayOf(oneOfType([element, node, func])),
  ]),
};

Button.defaultProps = {
  children: <></>,
};

export default Button;
