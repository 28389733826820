import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    html {
        overflow-x: hidden;
        min-height: 100%;
        position: relative;
        box-sizing: border-box;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    body {
        overflow-x: hidden;
        height: 100%;
        color: #676767;
        font-family: 'Noto Sans TC', sans-serif;
        background-color: white;
        margin: 0;
        padding: 0;
    }

    a, a:visited, a:hover {
        color: #006CFF;
    }
`;

export default GlobalStyles;
