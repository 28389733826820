import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalculator,
  faCalendarAlt,
  faTachometerAlt,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import SectionTitle from './sectionTitle';
import Input from './input';
import Button from './button';
import Label from './label';

const Intro = styled.p`
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.1rem;

  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 1.3rem;
  }
`;

const InputsContainer = styled.div`
  display: grid;
  justify-content: center;
  padding: 20px;
  grid-template-columns: 100%;
  grid-gap: 1rem;
  padding-top: 0;

  ${({ theme }) => theme.breakpoints.desktop} {
    grid-template-columns: 50% 50%;
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const FinalAmount = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding: 20px;
  text-align: center;

  span {
    display: block;

    &.number {
      margin-top: 10px;
      color: #35b33d;
    }

    &.italic {
      font-style: italic;
    }
  }
`;

const SingleInputContainer = styled.div``;

const ButtonContainer = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
`;

const Error = styled.div`
  color: red;
  margin-top: 10px;
  text-align: center;

  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
`;

const Calculator = () => {
  const [startReading, setStartReading] = useState(0);
  const [endReading, setEndReading] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [standingCharge, setStandingCharge] = useState(14.0);
  const [unitCharge, setUnitCharge] = useState(15.0);
  const [calculatedAmount, setCalculatedAmount] = useState(0);

  const [hasReadingError, setHasReadingError] = useState(false);
  const [hasDateError, setHasDateError] = useState(false);
  const [hasPlanError, setHasPlanError] = useState(false);

  const daysBetween = (date1, date2) => {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(new Date(date1) - new Date(date2));

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);
  };

  const makeButtonDoStuff = () => {
    checkReadingValues();
    checkPlanValues();
    checkDates();

    if (!hasPlanError && !hasReadingError && !hasDateError) {
      const numberOfDays = daysBetween(startDate, endDate);

      const finalStandingCharge = standingCharge * numberOfDays;
      const finalUnitCharge = (endReading - startReading) * unitCharge;
      const finalCharge = finalStandingCharge + finalUnitCharge;

      setCalculatedAmount(finalCharge);
    }
  };

  const checkReadingValues = () => {
    if (parseInt(startReading) > parseInt(endReading)) {
      setHasReadingError(true);
      return;
    }

    setHasReadingError(false);
  };

  const checkDates = () => {
    const firstDate = dayjs(startDate);
    const lastDate = dayjs(endDate);

    if (!firstDate.isValid() || !lastDate.isValid()) {
      setHasDateError(true);
      return;
    }

    if (lastDate.isBefore(firstDate)) {
      setHasDateError(true);
      return;
    }

    setHasDateError(false);
  };

  const checkPlanValues = () => {
    if (parseFloat(standingCharge) <= 0 || parseFloat(unitCharge) <= 0) {
      setHasPlanError(true);
      return;
    }

    setHasPlanError(false);
  };

  return (
    <>
      <Intro>
        Simply just input your two meter readings, dates, standing charge and
        kWh charge from your plan below and we will present you with how much
        you have spent in that date range on electricity.
      </Intro>
      <SectionTitle>
        <FontAwesomeIcon icon={faCalculator} /> Step 1: Input meter readings
      </SectionTitle>
      <InputsContainer>
        <Input
          placeholder="Input start meter reading..."
          type="number"
          onChange={(e) => setStartReading(e.target.value)}
          onBlur={checkReadingValues}
          data-testid="start-reading"
        />
        <Input
          placeholder="Input end meter reading..."
          type="number"
          onChange={(e) => setEndReading(e.target.value)}
          onBlur={checkReadingValues}
          data-testid="end-reading"
        />
      </InputsContainer>
      {hasReadingError ? (
        <Error data-testid="reading-error">
          Please check the values above and make sure the start value is lower
          than the end value
        </Error>
      ) : (
        <></>
      )}
      <SectionTitle>
        <FontAwesomeIcon icon={faCalendarAlt} /> Step 2: Input dates
      </SectionTitle>
      <InputsContainer>
        <SingleInputContainer>
          <Label>Input the date of the first meter reading</Label>
          <Input
            placeholder="Input start date..."
            type="date"
            onChange={(e) => setStartDate(e.target.value)}
            onBlur={checkDates}
            data-testid="start-date"
          />
        </SingleInputContainer>
        <SingleInputContainer>
          <Label>Input the date of the last meter reading</Label>
          <Input
            placeholder="Input end date..."
            type="date"
            onChange={(e) => setEndDate(e.target.value)}
            onBlur={checkDates}
            data-testid="end-date"
          />
        </SingleInputContainer>
      </InputsContainer>
      {hasDateError ? (
        <Error data-testid="dates-error">
          Please check the dates above and make sure the first date is before
          the last date
        </Error>
      ) : (
        <></>
      )}
      <SectionTitle>
        <FontAwesomeIcon icon={faTachometerAlt} /> Step 3: Input your plan
      </SectionTitle>
      <InputsContainer>
        <SingleInputContainer>
          <Label>Input standing charge in pence e.g. 14.50</Label>
          <Input
            placeholder="Input standing charge in pence"
            type="number"
            defaultValue="14.00"
            hasValue={true}
            onChange={(e) => setStandingCharge(e.target.value)}
            onBlur={checkPlanValues}
            data-testid="standing-charge"
          />
        </SingleInputContainer>
        <SingleInputContainer>
          <Label>Input unit rate kWh in pence e.g. 15.00</Label>
          <Input
            placeholder="Input unit rate kWh in pence"
            type="number"
            defaultValue="15.00"
            hasValue={true}
            onChange={(e) => setUnitCharge(e.target.value)}
            onBlur={checkPlanValues}
            data-testid="kwh-charge"
          />
        </SingleInputContainer>
      </InputsContainer>
      {hasPlanError ? (
        <Error data-testid="charge-error">
          Please check the values above and make sure they are correct
        </Error>
      ) : (
        <></>
      )}
      {calculatedAmount > 0 ? (
        <FinalAmount data-testid="final-amount">
          <span className="italic">
            Between {dayjs(startDate).format('ddd, MMM D, YYYY')} and{' '}
            {dayjs(endDate).format('ddd, MMM D, YYYY')} you used:
          </span>
          <span className="number">
            {'£' +
              (Number(calculatedAmount / 100) || 0)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
              'p'}
          </span>
        </FinalAmount>
      ) : (
        <></>
      )}
      <ButtonContainer>
        <Button onClick={makeButtonDoStuff} data-testid="calculate">
          <FontAwesomeIcon icon={faCheck} /> Calculate
        </Button>
      </ButtonContainer>
    </>
  );
};

export default Calculator;
