import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import GlobalStyles from './components/styles/globalStyles';
import Container from './components/layout/container';
import theme from './theme';
import Calculator from './components/calculator/calculator';

const Heading = styled.h1`
  background-color: #313131;
  border-bottom: 4px solid #ff0080;
  color: white;
  margin-top: 0;
  padding: 1rem;
  font-size: 1.3rem;

  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 1.5rem;
  }
`;

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Heading>
        <Container>
          <FontAwesomeIcon icon={faBolt} color="#ff0080" /> Simple Electricity
          Calculator
        </Container>
      </Heading>
      <Container>
        <Calculator />
      </Container>
    </ThemeProvider>
  );
};

export default App;
